body[data-route="Workspaces/Flextime"] .number-widget-box .widget-title span {
  font-size: var(--text-lg);
  margin-top: -4px;
}

body[data-route="Workspaces/Flextime"] .number-widget-box,
body[data-route="Workspaces/Flextime"] .quick-list-widget-box {
  box-shadow: var(--shadow-base) !important;
}

[data-theme=dark] body[data-route="Workspaces/Flextime"] .number-widget-box,
[data-theme=dark] body[data-route="Workspaces/Flextime"] .quick-list-widget-box {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06), 0 0 4px rgba(0, 0, 0, 0.27) !important;
}

body[data-route="Workspaces/Flextime"] .number-widget-box .widget-content {
  display: block !important;
  padding-top: 15px !important;
}

div[number_card_name="Employees present"] .number-widget-box .widget-content {
  padding-top: 0 !important;
}

div[number_card_name="Employees present"] .widget-content {
  padding-top: 0;
}

body[data-route="Workspaces/Flextime"] .ce-paragraph {
  padding-bottom: 5px;
  border-radius: 0;
  font-size: 17px;
  color: var(--heading-color);
}

[data-theme=dark] body[data-route="Workspaces/Flextime"] .ce-paragraph {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1215686275);
}

[data-theme=light] body[data-route="Workspaces/Flextime"] .ce-paragraph {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1490196078);
}

body[data-route="Workspaces/Flextime"] .ce-header span.h4 {
  font-weight: normal;
}

#hr_time_dashboard_flextime_balance .balance {
  float: right;
}

#hr_time_dashboard_flextime_balance .trend,
#hr_time_dashboard_flextime_balance .balance {
  font-weight: normal;
}

#hr_time_dashboard_flextime_balance .trend {
  line-height: 25px;
}

#hr_time_dashboard_flextime_balance .balance {
  line-height: 27px;
}

#hr_time_dashboard_flextime_balance .balance .hours {
  font-size: 21px;
}

#hr_time_dashboard_flextime_balance .trend .absolute,
#hr_time_dashboard_flextime_balance .trend .meta {
  font-size: 13px;
}

#hr_time_dashboard_flextime_balance .balance .minutes {
  font-size: 16px;
}

#hr_time_dashboard_flextime_balance .balance .minutes,
#hr_time_dashboard_flextime_balance .trend .meta {
  color: var(--text-muted) !important;
}

#hr_time_dashboard_flextime_balance .trend {
  float: left;
  text-align: left;
}

#hr_time_dashboard_flextime_balance .trend .icon {
  font-size: 16px;
}

#hr_time_dashboard_flextime_balance .positive {
  color: var(--dark-green-500);
}

#hr_time_dashboard_flextime_balance .negative {
  color: var(--red);
}

#hr_time_nc_employees_present .count {
  text-align: center;
  padding: 10px;
}

#hr_time_nc_employees_present .show_all_btn {
  border-radius: var(--border-radius);
  background-color: var(--btn-default-bg);
  width: 100%;
  font-size: var(--text-md);
  line-height: 1.2;
  padding: 10px var(--padding-sm);
  font-weight: 400;
  text-align: center;
}

#hr_time_nc_employees_present .show_all_btn:hover {
  background-color: var(--btn-default-hover-bg);
}

#hr_time_number_card_checkin_status .checkin_status {
  line-height: 48px;
  padding-right: 9px;
  padding-left: 11px;
  border-radius: 8px;
}

.navbar .checkin_status {
  line-height: 28px;
  padding-right: 9px;
  padding-left: 11px;
  border-radius: 8px;
  height: 28px;
  margin-top: 4px;
  margin-right: 10px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.datatable .checkin_status {
  line-height: 21px;
  padding-right: 9px;
  padding-left: 11px;
  border-radius: 5px;
  height: 21px;
  margin-right: 0;
  margin-top: -1px;
}

.checkin_status.work {
  background-color: var(--bg-green);
  color: var(--text-on-green);
}

.checkin_status.break {
  background-color: var(--bg-blue);
  color: var(--text-on-blue);
}

.checkin_status.out {
  background-color: var(--bg-grey);
  color: var(--text-on-grey);
}

.checkin_status .fa {
  padding-left: 7px;
  font-style: normal;
}

.navbar .checkin_status .label,
.datatable .checkin_status .label {
  float: left;
}

@media (min-width: 326px) and (max-width: 400px) {
  .navbar .checkin_status {
    white-space: unset;
    overflow: unset;
    text-align: center;
    max-width: 115px;
    height: 43px;
    line-height: 21px;
    margin-top: 0;
  }
  .navbar .checkin_status .label {
    text-align: center;
    width: 64px;
  }
  .checkin_status .fa {
    padding-top: 13px;
  }
}
@media (max-width: 326px) {
  .navbar .checkin_status .label {
    float: left;
    max-width: 26px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.modal .modal-body {
  padding-bottom: 0 !important;
}

label#worklog_section_label {
  margin-bottom: 0 !important;
}

.worklog-status-alert {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.1rem 1.25rem;
  border-radius: 0.4rem;
  opacity: 0.9;
  font-size: var(--text-sm);
  margin-top: 1rem;
  margin-bottom: 0;
  border-width: 1px;
  border-style: solid;
}
.worklog-status-alert.danger {
  color: var(--alert-text-danger);
  background: var(--alert-bg-danger);
}
.worklog-status-alert.success {
  color: var(--alert-text-success);
  background: var(--alert-bg-success);
}

[data-fieldname=worklog_box] .clearfix {
  height: 10px !important;
}
[data-fieldname=worklog_box] textarea {
  min-height: 70px;
  max-height: 200px;
}